import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const PrevArrow = () => (
  <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 28.875C24.0416 28.875 29.75 23.3345 29.75 16.5C29.75 9.66548 24.0416 4.125 17 4.125C9.95837 4.125 4.25 9.66548 4.25 16.5C4.25 23.3345 9.95837 28.875 17 28.875Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M16.1953 20.8752L11.6875 16.5L16.1953 12.1248" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.6875 16.5H22.3125" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const NextArrow = () => (
  <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 28.875C24.0416 28.875 29.75 23.3345 29.75 16.5C29.75 9.66548 24.0416 4.125 17 4.125C9.95837 4.125 4.25 9.66548 4.25 16.5C4.25 23.3345 9.95837 28.875 17 28.875Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M17.8047 20.8752L22.3125 16.5L17.8047 12.1248" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.6875 16.5H22.3125" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const banners = [
  {
    image: '/banners/Gearfocus_WebsiteHeaders1.webp',
    title: 'Looking for Top Gear<br />at Unbeatable Prices?',
    subtitle: 'Secure transactions, a reliable experience, and quality inventory from verified sellers!',
    buttonText: 'Shop Now',
    buttonLink: '/shop',
    textColor: 'text-black'
  },
  {
    image: '/banners/Gearfocus_WebsiteHeaders2.webp',
    title: 'Thousands of Items.<br />Updated Daily.',
    subtitle: 'Lots of products from lots of brands. Check back often to see what\'s been added',
    buttonText: 'Shop Now',
    buttonLink: '/shop',
    textColor: 'text-white'
  },
  {
    image: '/banners/Gearfocus_WebsiteHeaders3.webp',
    title: 'List for Free.<br />Sell for More.',
    subtitle: 'Discover our collection of high-quality lenses',
    buttonText: 'Start Listing',
    buttonLink: '/sellyourgear',
    textColor: 'text-white'
  },
  {
    image: '/banners/Gearfocus_WebsiteHeaders4.webp',
    title: 'For Creatives.<br />By Creatives.',
    subtitle: 'GearFocus is where this passionate community can share their love of the equipment they use and the art they create with it.',
    buttonText: 'Check Out Our Blog',
    buttonLink: '/blog',
    textColor: 'text-white'
  },
  {
    image: '/banners/Gearfocus_WebsiteHeaders5.webp',
    title: 'What You See<br />is What You Get.',
    subtitle: 'Almost every product photo on this site is the real-deal. In the rare case when it isn\'t, we\'ll let you know with a clear label.',
    buttonText: 'FAQs',
    buttonLink: '/help',
    textColor: 'text-black'
  }
];

const HomepageHeader = () => {
  const [currentTextColor, setCurrentTextColor] = useState('text-black');
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const CustomNextArrow = ({ onClick }: any) => (
    <button
      onClick={onClick}
      className={`absolute bottom-[2rem] right-16 z-10 flex h-12 w-12 items-center justify-center transition-colors hover:text-orange-500 ${currentTextColor}`}
      aria-label="Next slide"
    >
      <NextArrow />
    </button>
  );

  const CustomPrevArrow = ({ onClick }: any) => (
    <>
      <button
        onClick={onClick}
        className={`absolute bottom-[2rem] right-[84px] z-10 flex h-12 w-12 items-center justify-center transition-colors hover:text-orange-500 ${currentTextColor}`}
        aria-label="Previous slide"
      >
        <PrevArrow />
      </button>
      <div className={`absolute bottom-[2rem] right-[76px] z-10 h-12 w-[2px] ${currentTextColor === 'text-white' ? 'bg-white' : 'bg-black'}`} />
    </>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_: number, nextSlide: number) => {
      setCurrentTextColor(banners[nextSlide].textColor);
    },
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <div 
      className="relative w-full select-none"
    >
      <Slider {...settings}>
        {banners.map((banner, index) => (
          <div key={index} className="relative max-h-[500px] h-[32vw] w-full flex items-center justify-center overflow-hidden">
            <Image
              src={banner.image}
              alt={banner.title}
              width={width}
              height={height}
              style={{ width: '100%', height: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
              priority
            />
            <div className={`absolute inset-0 ${banner.textColor === 'text-black' ? '' : 'bg-black/30'}`}>
              <div className="relative mx-[3rem] h-full max-w-default sm:px-4">
                <div className="flex h-full flex-col justify-center">
                  <h1 
                    className={`max-w-[60rem] text-[4.25rem] lg:text-[5.5rem] font-semibold leading-tight ${banner.textColor}`}
                    dangerouslySetInnerHTML={{ __html: banner.title }}
                  />
                  <p className={`mt-4 max-w-[42rem] text-sm ${banner.textColor === 'text-black' ? 'text-black/90' : 'text-white/90'} sm:text-[1.8rem] leading-[1.2]`}>
                    {banner.subtitle}
                  </p>
                  <div className="mt-6">
                    <Link
                      href={banner.buttonLink}
                      className="inline-block rounded-[1rem] bg-orange-500 px-8 py-4 text-2xl sm:text-3xl font-semibold text-white transition-colors hover:bg-orange-600"
                    >
                      {banner.buttonText}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomepageHeader;
